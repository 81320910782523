/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Link } from "gatsby";
import React from "react";
import LocalizedStrings from "react-localization";

import Layout from "../components/Layout";
import { typography, colors } from "../components/styles";

const strings = new LocalizedStrings({
  en: {
    body:
      "This page does not exist or has been deleted. If you think this is a mistake, please contact us.",
    button: "Go back home",
    title: "Looks like you're in the wrong place",
  },
  fr: {
    body:
      "Cette page n'existe pas, ou plus. Si vous pensez qu'il s'agit d'une erreur, merci de nous le signaler.",
    button: "Retourner à l'accueil",
    title: "Il semblerait que vous soyez au mauvais endroit",
  },
});

const NotFound = ({ location }) => (
  <Layout location={location}>
    <div
      css={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem 1rem",
        textAlign: "center",
      }}
    >
      <div
        css={{
          ...typography.serif,
          fontSize: "2rem",
          margin: "0 0 2rem",
        }}
      >
        {strings.title}
      </div>
      <div
        css={{
          ...typography.sansSerif,
          fontSize: "1.4rem",
          margin: "0 0 2rem",
        }}
      >
        {strings.body}
      </div>
      <Link
        to="/"
        css={{
          ...typography.serif,
          ":hover": {
            transform: "translateX(1rem)",
          },
          color: colors.strong,
          fontWeight: "bold",
          margin: "0 0 2rem",
          textDecoration: "none",
          transition: "transform 120ms ease-in",
        }}
      >
        {strings.button}
        {' '}
        →
      </Link>
    </div>
  </Layout>
);

export default NotFound;
